import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Side Plank 3x:30/side & Superman Extensions 3×10/side`}</p>
    <p>{`then,`}</p>
    <p>{`Snatch Grip Deadlift 4×6\\@approx 100% Snatch max`}</p>
    <p>{`then,`}</p>
    <p>{`Death by:`}</p>
    <p>{`1-Deadlift (225/155)`}</p>
    <p>{`1-Bar Facing Burpee`}</p>
    <p><em parentName="p">{`*`}{`add 1 rep of each every minute on the minute until failure.`}</em></p>
    <p><em parentName="p">{`*`}{`compare to 6/4/20`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      